<template>
    <div class="orderBlock container">
        <Error404/>
    </div>
  </template>
  
<script>

import Error404 from '@/components/Error404.vue';

export default {

    name: 'OrderPageView',

    data() {
      return {
      }
    },

    methods: {
    },

    components: {
    Error404,
  }
}
</script>
 
<style lang="sass" scoped>
</style>